import React from "react";
import t2 from "../../assets/img/t2.png";
import t3 from "../../assets/img/t3.png";
import t5 from "../../assets/img/t5.png";
import t6 from "../../assets/img/hhh.jpeg";

const TeamHero = () => {
  const data = [
    {
      id: 1,
      img: t6,
      name: "Piyush Shukla",
      designation: "Founder, Top Researcher, Award-Winning Threat Hunter",
    },
    {
      id: 2,
      img: t2,
      name: "Ashmeet Singh",
      designation: "Head of Blockchain, Top Researcher in Blockchain, AI and ML",
    },
    {
      id: 3,
      img: t3,
      name: "Vaibhav Sethi",
      designation: "Head of Business Development, Ex Spearbit",
    },
    {
      id: 5,
      img: t5,
      name: "Mohit Yadav",
      designation: "Blockchain Security Researcher, SafeEdges",
    },
  ];

  return (
    <div className="team-hero py-[75px] bg-dark-800">
      <div className="container">
        <header className="text-center mb-12">
          <h2 className="text-white text-[40px] font-semibold">Meet our Leadership Team</h2>
          <p className="text-white text-lg mb-[50px]">
            Dedicated to safer blockchain, committed to innovation and security.
          </p>
        </header>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {data.map((member) => (
            <div
              key={member.id}
              className="bg-white/10 backdrop-blur-md rounded-2xl shadow-xl p-6 text-center"
            >
              <img
                src={member.img}
                alt={member.name}
                className="w-24 h-24 mx-auto rounded-full mb-4"
              />
              <h3 className="text-lg font-semibold text-gray-200">
                {member.name}
              </h3>
              <p className="text-sm text-gray-400">{member.designation}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamHero;
