import React from "react";
import { motion } from "framer-motion";

const Differential = () => {
  return (
    <div className="py-10 bg-dark-900">
      <div className="container mx-auto flex flex-col lg:flex-row items-center px-4">
        {/* Text Section */}
        <div className="lg:w-1/2 w-full mb-8 lg:mb-0">
          <header className="mb-6">
            <h2 className="text-white text-3xl lg:text-4xl font-semibold mb-4 border-b-4 border-blue-500 inline-block pb-2">
              Competitive Auditing for Unmatched Results
            </h2>
            <p className="text-gray-400 text-base lg:text-lg mb-6">
              Safe Edges introduces a groundbreaking approach where 3 independent lead auditors work on the same project, competing to identify the most vulnerabilities and risks. This ensures every possible angle is covered, with unparalleled precision and depth.
            </p>
            <ul className="text-gray-400 text-sm lg:text-base list-disc list-inside space-y-2">
              <li>3 Lead Auditors Working Independently</li>
              <li>Cross-Verification of Findings</li>
              <li>Incentive-Based Competition for Thoroughness</li>
              <li>Collaborative Resolution Post-Competition</li>
              <li>Detailed Final Report with Comparative Insights</li>
            </ul>
          </header>
        </div>

        {/* Process Section */}
        <div className="lg:w-1/2 w-full flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
          {/* Auditor 1 */}
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-gradient-to-r from-gray-700 via-gray-800 to-gray-900 text-white rounded-xl shadow-2xl p-4 sm:p-6 w-40 sm:w-48 text-center transform hover:scale-105 transition-transform border border-gray-600"
          >
            <h3 className="font-semibold text-sm sm:text-lg text-gray-300 hover:text-white">Step 1</h3>
            <p className="text-gray-400 text-xs mt-2">Analyzing system-level risks.</p>
          </motion.div>

          {/* Animated Arrow */}
          <motion.div
            className="w-10 sm:w-12 h-0.5 bg-gradient-to-r from-blue-500 to-blue-300"
            initial={{ width: 0 }}
            animate={{ width: "2.5rem" }}
            transition={{ duration: 0.5, delay: 0.2 }}
          ></motion.div>

          {/* Auditor 2 */}
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="bg-gradient-to-r from-gray-700 via-gray-800 to-gray-900 text-white rounded-xl shadow-2xl p-4 sm:p-6 w-40 sm:w-48 text-center transform hover:scale-105 transition-transform border border-gray-600"
          >
            <h3 className="font-semibold text-sm sm:text-lg text-gray-300 hover:text-white">Step 2</h3>
            <p className="text-gray-400 text-xs mt-2">Targeting application vulnerabilities.</p>
          </motion.div>

          {/* Animated Arrow */}
          <motion.div
            className="w-10 sm:w-12 h-0.5 bg-gradient-to-r from-blue-500 to-blue-300"
            initial={{ width: 0 }}
            animate={{ width: "2.5rem" }}
            transition={{ duration: 0.5, delay: 0.6 }}
          ></motion.div>

          {/* Auditor 3 */}
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.8 }}
            className="bg-gradient-to-r from-gray-700 via-gray-800 to-gray-900 text-white rounded-xl shadow-2xl p-4 sm:p-6 w-40 sm:w-48 text-center transform hover:scale-105 transition-transform border border-gray-600"
          >
            <h3 className="font-semibold text-sm sm:text-lg text-gray-300 hover:text-white">Step 3</h3>
            <p className="text-gray-400 text-xs mt-2">Performing in-depth code reviews.</p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Differential;
