import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

const NewsFeatured = () => {
    return (
        <div className='featured'>
            <div className="container flex justify-center items-center">
                <Swiper
                    modules={[Navigation, Pagination, Autoplay]}
                    navigation
                    pagination={{ clickable: true }}
                    spaceBetween={30}
                    slidesPerView={1}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 600,
                        disableOnInteraction: false,
                    }}
                    className="mySwiper w-full max-w-[400px] relative"
                >
<SwiperSlide className="flex justify-center items-center">
    <div className="featured-card relative">
        <img src="https://imageio.forbes.com/specials-images/imageserve/63bdffc05989c30c33964a41/Crypto-com/960x0.jpg" 
            className='w-[400px] h-[400px] object-cover rounded-[20px]' 
            alt="Blockchain News" 
        />
    </div>
</SwiperSlide>

<SwiperSlide className="flex justify-center items-center">
    <div className="featured-card relative">
        <img src="https://www.investopedia.com/thmb/FP3_ZH1k2B-qhvAiyTaNsHHxpV4=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/cryptocurrency-mining-10a5fbe4e7204768ae35c504724e7fb4.jpg" 
            className='w-[400px] h-[400px] object-cover rounded-[20px]' 
            alt="Crypto Mining" 
        />
    </div>
</SwiperSlide>

<SwiperSlide className="flex justify-center items-center">
    <div className="featured-card relative">
        <img src="https://img.freepik.com/free-vector/cryptocurrency-bitcoin-golden-coin-background_1017-31505.jpg" 
            className='w-[400px] h-[400px] object-cover rounded-[20px]' 
            alt="Bitcoin" 
        />
    </div>
</SwiperSlide>

                    {/* Other slides... */}

                    <style jsx>{`
                        .swiper-button-prev,
                        .swiper-button-next {
                            width: 50px !important;
                            background: rgba(255, 255, 255, 0.5);
                            padding: 30px 20px;
                            border-radius: 5px;
                        }
                        
                        .swiper-pagination {
                            bottom: 20px !important;
                        }
                        
                        .swiper-button-prev:after,
                        .swiper-button-next:after {
                            font-size: 20px !important;
                            font-weight: bold;
                        }
                    `}</style>
                </Swiper>
            </div>
        </div>
    );
};

export default NewsFeatured;
