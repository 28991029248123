import React from "react";
import { Download } from "lucide-react";

const reports = [
  {
    companyName: "Fuel chain",
    marketCap: "$682,3558",
    platform: "Fuel ignition",
    languages: "sway",
    auditStatus: "Ongoing",
    lastAudit: "2025",
    // reportLink: "https://drive.google.com/drive/u/0/my-drive/fuel.pdf",
  },
  {
    companyName: "swaylend",
    marketCap: "5,748,06",
    platform: "Fuel",
    languages: "sway",
    auditStatus: "Ongoing",
    lastAudit: "2024",
    // reportLink: "https://drive.google.com/drive/u/0/my-drive/swaylend-report.pdf",
  },
  {
    companyName: "fluid protocol",
    marketCap: "$8,167,8",
    platform: "Fuel",
    languages: "sway",
    auditStatus: "Ongoing",
    lastAudit: "Nov, 2024",
    // reportLink: "https://drive.google.com/drive/u/0/my-drive/crypto-report.pdf",
  },
  {
    companyName: "Griffy",
    marketCap: "$6,758,8",
    platform: "Fuel",
    languages: "sway",
    auditStatus: "Ongoing",
    lastAudit: "Dec, 2024",
    // reportLink: "https://drive.google.com/drive/u/0/my-drive/bybit-report.pdf",
  },
  {
    companyName: "sprk",
    marketCap: "$5,748,06",
    platform: "Fuel",
    languages: "sway",
    auditStatus: "Ongoing",
    lastAudit: "Oct 24, 2024",
    // reportLink: "https://drive.google.com/drive/u/0/my-drive/near-report.pdf",
  },
  {
    companyName: "fuelup",
    marketCap: "$5,748,3",
    platform: "Fuel",
    languages: "sway",
    auditStatus: "Ongoing",
    lastAudit: "Dec, 2024",
    // reportLink: "https://drive.google.com/drive/u/0/my-drive/near-report.pdf",
  },
];

const Button = ({ children, className, ...props }) => {
  return (
    <button
      className={`bg-white-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

const ReportsPage = () => {
  return (
    <div className="bg-black py-16">
      <div className="container mx-auto px-6">
        <div className="text-center mb-12">
          <h2 className="text-white text-3xl md:text-4xl font-bold mt-2">
            Audited Reports by Competing Lead Auditors
          </h2>
          <p className="text-gray-400 mt-4">
            Each report reflects the outcome of a security audit conducted by one of three independent lead auditors working competitively to ensure the highest standards.
          </p>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto border-collapse border border-gray-700">
            <thead>
              <tr className="bg-[#1a1a1a] text-white">
                <th className="px-4 py-2 text-left">Company Name</th>
                <th className="px-4 py-2 text-left">Market Cap</th>
                <th className="px-4 py-2 text-left">Platform</th>
                <th className="px-4 py-2 text-left">Languages</th>
                <th className="px-4 py-2 text-left">Last Audit Status</th>
                <th className="px-4 py-2 text-left">Last Audit</th>
                <th className="px-4 py-2 text-center">Report</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report, index) => (
                <tr key={index} className="border border-gray-700 text-gray-300">
                  <td className="px-4 py-2">{report.companyName}</td>
                  <td className="px-4 py-2">{report.marketCap}</td>
                  <td className="px-4 py-2">{report.platform}</td>
                  <td className="px-4 py-2">{report.languages}</td>
                  <td className="px-4 py-2 text-green-400">{report.auditStatus}</td>
                  <td className="px-4 py-2">{report.lastAudit}</td>
                  <td className="px-4 py-2 text-center">
                    <a
                      href={report.reportLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className="text-white-500 hover:underline text-lg font-semibold">
                        <Download size={16} />
                      </Button>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ReportsPage;
