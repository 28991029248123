
import { useEffect } from 'react';
import { useState } from 'react';

const Values = () => {
    const data = [
        {
            id: 1,
            value: 2100,
            description: "Critical exploits detected"
        },
        {
            id: 2,
            value: 1260000,
            description: "We secured on protocols"
        },
        {
            id: 3,
            value: 300000,
            description: "Total value monitored"
        }
    ];

    const [counts, setCounts] = useState(data.map(item => 0));

    useEffect(() => {
        const intervals = data.map((item, index) => {
            const increment = item.value / 200; // Adjust the divisor to control the speed
            return setInterval(() => {
                setCounts(prevCounts => {
                    const newCounts = [...prevCounts];
                    if (newCounts[index] < item.value) {
                        newCounts[index] = Math.min(newCounts[index] + increment, item.value);
                    }
                    return newCounts;
                });
            }, 10);
        });

        return () => intervals.forEach(interval => clearInterval(interval));
    }, [data]);

    return (
        <div className='values py-[60px] bg-[#181818]'>
            <div className="container">
                <div className="flex justify-center items-center text-center lg:flex-row flex-col gap-[50px] md:gap-[100px]">
                    {
                        data.map((item, index) => (
                            <div key={item.id} className={`flex ${index !== 0 && "border-l border-[#181818]"} md:pl-[100px] justify-center text-center items-center`}>
                                <div className="left">
                                    <h2 className='font-semibold text-white text-[32px] md:text-[50px]'>
                                        {Math.floor(counts[index]).toLocaleString()}
                                    </h2>
                                    <p>
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}

export default Values;