import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaTelegramPlane, FaTwitter } from "react-icons/fa";
import logo from "../../assets/img/logo.png";
import fuelLogo from "../../assets/img/l8.svg";
import swayLogo from "../../assets/img/sway-logo.png";

const AnimatedBlogCard = ({ title, imageUrl, linkUrl }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleImageClick = () => {
    window.open(linkUrl, '_blank', 'noopener,noreferrer');
  };

  return (
    <div 
      className={`
        border border-gray-700 rounded-2xl p-6 
        transition-all duration-500 ease-in-out
        transform hover:scale-105 hover:shadow-2xl
        ${isHovered ? 'rotate-3 translate-y-[-10px]' : ''}
        bg-dark-800 
        relative 
        overflow-hidden
      `}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <h3 className="text-xl font-bold mb-4 text-white relative z-10">{title}</h3>
      <div 
        className="relative overflow-hidden rounded-xl cursor-pointer group"
        onClick={handleImageClick}
      >
        <img
          src={imageUrl}
          alt={title}
          className={`
            w-full h-60 object-cover mb-4 
            transition-transform duration-500
            ${isHovered ? 'scale-110' : 'scale-100'}
            group-hover:brightness-75
          `}
        />
        <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-black bg-opacity-50">
          <span className="text-white font-bold text-lg">Read Full Post</span>
        </div>
      </div>
      <a 
        href={linkUrl}
        target="_blank" 
        rel="noopener noreferrer"
        className={`
          bg-light-green-600 hover:bg-light-green-700 
          text-white font-bold py-2 px-4 rounded 
          transition duration-300 
          transform hover:scale-105 
          inline-block 
          relative z-10
          ${isHovered ? 'translate-y-[-5px]' : ''}
        `}
      >
        Read Full Post
      </a>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="bg-dark-900 text-white py-12 perspective-1000">
      <div className="container mx-auto px-4">
        
        {/* Blog Section with 3D Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
          <AnimatedBlogCard 
            title="Fees on Fuel: Ignition"
            imageUrl="https://miro.medium.com/v2/resize:fit:828/format:webp/1*X1zm7-fHtRVgwJUZgNJuXQ.jpeg"
            linkUrl="https://medium.com/@safe-edges/fees-on-fuel-ignition-mastering-fuel-network-gas-dynamics-805ff50dcf7d"
          />
          <AnimatedBlogCard 
            title="Understanding UTXO and Parallel Transactions"
            imageUrl="https://miro.medium.com/v2/resize:fit:828/format:webp/1*IpdvDT-qsKiB99ilYQbe-g.png"
            linkUrl="https://medium.com/@safe-edges/understanding-utxo-and-parallel-transactions-in-fuel-a-comprehensive-blog-a71b01bbfee8"
          />
          <AnimatedBlogCard 
            title="Pike Finance Exploit Analysis"
            imageUrl="https://miro.medium.com/v2/resize:fit:828/format:webp/1*v7egttCuTOYsMgAsS7pqUA.png"
            linkUrl="https://medium.com/@safe-edges/pike-finance-exploit-1-6-million-hack-analysis-33fa06c33c7c"
          />
        </div>

        <div className="mt-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="border border-gray-700 rounded-2xl p-6 md:p-8 flex flex-col items-center md:items-start transform hover:scale-105 transition-transform duration-300">
              <Link to="/" className="mb-6 transition-transform duration-300 hover:scale-110">
                <img 
                  src={logo} 
                  alt="Safe Edges Logo" 
                  className="w-24 h-24 object-contain" 
                  onError={(e) => {
                    e.target.style.display = 'none';
                    e.target.nextElementSibling.style.display = 'block';
                  }}
                />
                <div className="hidden text-3xl font-bold text-center">SafeEdges</div>
              </Link>
              <p className="text-sm leading-relaxed text-center md:text-left">
                Empowering clients by proactively detecting and preventing criminal
                activities, ensuring the highest level of security and integrity
                before transactions are approved on the blockchain.
              </p>
            </div>

            <div className="border border-gray-700 rounded-2xl p-6 md:p-8 bg-gradient-to-br from-light-green-900 to-oliv-green-700 transform hover:scale-105 transition-transform duration-300">
              <h3 className="text-2xl font-bold mb-4">Discover Sway Language</h3>
              <img 
                src={swayLogo} 
                alt="Sway Language Logo" 
                className="w-16 h-16 mb-4 object-contain" 
                onError={(e) => {
                  e.target.style.display = 'none';
                  e.target.nextSibling.style.display = 'block';
                }}
              />
              <div className="hidden text-4xl font-bold mb-4">Sway</div>
              <p className="text-sm leading-relaxed mb-6">
                Sway is a domain-specific language (DSL) for the Fuel blockchain, designed 
                to enable developers to write safe and efficient smart contracts.
              </p>
              <a 
                href="https://fuellabs.github.io/sway/v0.66.2/book/" 
                target="_blank" 
                rel="noopener noreferrer"
                className="bg-white-500 hover:dark-600 text-white font-bold py-2 px-4 rounded transition duration-300 transform hover:scale-105"
              >
                Read More
              </a>
            </div>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-700">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm mb-4 md:mb-0">
              © 2024 SafeEdges. All rights reserved.
            </p>
            <div className="flex items-center gap-6">
              <a href="https://twitter.com/safe_edges" className="text-2xl hover:text-blue-400 transition duration-300 transform hover:scale-110" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </a>
              <a href="https://t.me/bugtester25" className="text-2xl hover:text-blue-400 transition duration-300 transform hover:scale-110" target="_blank" rel="noopener noreferrer">
                <FaTelegramPlane />
              </a>
              
              <div className="flex items-center gap-2">
                <span className="text-xs text-gray-400" style={{ opacity: 0.5 }}>Trusted by</span>
                <img 
                  src={fuelLogo} 
                  alt="Trusted by Fuel"
                  className="w-24 object-contain transition-transform duration-300 hover:scale-110" 
                  onError={(e) => {
                    e.target.style.display = 'none';
                    e.target.nextSibling.style.display = 'block';
                  }}
                />
              </div>

              <span className="hidden text-sm">Trusted by Fuel</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;