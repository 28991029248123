import React, { useEffect, useMemo } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const SASSection = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  const logos = useMemo(() => [
    { name: 'solana', url: 'https://cryptologos.cc/logos/solana-sol-logo.png' },
    { name: 'bitcoin', url: 'https://cryptologos.cc/logos/bitcoin-btc-logo.png' },
    { name: 'bnb', url: 'https://cryptologos.cc/logos/binance-coin-bnb-logo.png' },
    { name: 'ethereum', url: 'https://cryptologos.cc/logos/ethereum-eth-logo.png' },
    { name: 'base', url: 'https://altcoinsbox.com/wp-content/uploads/2023/02/base-logo.png' },
    { name: 'ton', url: 'https://cryptologos.cc/logos/toncoin-ton-logo.png?v=040' },
    { name: 'optimism', url: 'https://cryptologos.cc/logos/optimism-ethereum-op-logo.png?v=040' },
    { name: 'aptos', url: 'https://cryptologos.cc/logos/aptos-apt-logo.png?v=040' },
    { name: 'fuel', url: 'https://chainbroker.io/_next/image/?url=https%3A%2F%2Fstatic.chainbroker.io%2Fmediafiles%2Fprojects%2Ffuel-network%2Ffuuel.jpg&w=768&q=75' },
    { name: 'avalanche', url: 'https://cryptologos.cc/logos/avalanche-avax-logo.png?v=040' },
    { name: 'arbitrum', url: 'https://cryptologos.cc/logos/arbitrum-arb-logo.png?v=040' },
    { name: 'near', url: 'https://cryptologos.cc/logos/near-protocol-near-logo.png?v=040' },
    { name: 'berachain', url: 'https://cdn.prod.website-files.com/633c67ced5457aa4dec572be/667ac3022260a22071b3cf37_u_b_f51944d0-b527-11ee-be26-a5e0a0cc15ce.png' }
  ], []);

  const logoVariants = {
    initial: { scale: 0.7, opacity: 0 },
    animate: (index) => ({
      scale: 1,
      opacity: 1,
      rotate: 360,
      transition: {
        delay: index * 0.1,
        type: "spring",
        stiffness: 120,
        damping: 10
      }
    }),
    hover: { 
      scale: 1.15,
      rotate: [-5, 5, -5],
      transition: { 
        type: "spring",
        stiffness: 300,
        bounce: 0.5
      }
    },
    tap: { 
      scale: 0.9,
      rotate: 0
    }
  };

  return (
    <motion.section
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { 
          opacity: 1, 
          y: 0, 
          transition: { 
            duration: 0.5,
            when: "beforeChildren",
            staggerChildren: 0.1
          } 
        }
      }}
      className="relative bg-[#0d0511] text-white py-16 px-8 flex flex-col items-center"
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-7xl mx-auto">
        <div className="flex flex-col justify-center items-center">
          <motion.div 
            className="flex justify-center items-center gap-6 flex-wrap"
          >
            {logos.map((chain, index) => (
              <motion.div
                key={index}
                custom={index}
                initial="initial"
                animate="animate"
                whileHover="hover"
                whileTap="tap"
                variants={logoVariants}
                className="w-20 h-20 flex justify-center items-center 
                  rounded-full bg-gradient-to-r from-gray-700 to-gray-500 
                  shadow-xl border border-gray-600 overflow-hidden"
              >
                <img
                  src={chain.url}
                  alt={chain.name}
                  className="w-14 h-14 object-contain"
                />
              </motion.div>
            ))}
          </motion.div>
        </div>

        <div className="flex flex-col justify-center text-center md:text-left">
          <h2 className="text-2xl font-semibold mb-4">Safe Edges</h2>
          <p className="text-base leading-relaxed">
            Safe Edges is a decentralized network of industry-leading security researchers committed to securing the most complex and mission-critical Web3 protocols. With unmatched expertise and a deep understanding of decentralized technologies, we provide top-tier auditing services that safeguard your projects from evolving threats. Trusted by top Web3 innovators, Safe Edges is your partner in securing the future of the decentralized world.
          </p>
        </div>
      </div>
    </motion.section>
  );
};

export default SASSection;