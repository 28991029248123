import React from "react";
import Cards from "../../components/cards";
import TrustedBy from "../../components/trusted-by";
import Values from "../../components/values";
import SecureFuture from "../../components/secure-future";
import OurSolution from "../../components/our-solution";
import Differential from "../../components/differential";
import MapToSafety from "../../components/map-to-safety";
import Phases from "../../components/phases";
import HomeHero from "../../components/home-hero";
import AuditedReports from "../Reports";
// import NewsFeatured from "../../components/news-featured";

const Homepage = () => {
  return (
    <>
      <HomeHero /> 
      {/* <NewsFeatured/> */}
      <Cards />
      <TrustedBy />
      <SecureFuture />
      <Values />
      <OurSolution />
      <Differential />
      <MapToSafety />
      <AuditedReports/>
      {/* <Phases /> */}
      
    </>
  );
};

export default Homepage;
